import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";

const useStyles = makeStyles((theme) => ({
  radioButtonForm: theme.radioButtonForm,
}));

export const RadioComponent = ({ name, state, options, setState }) => {
  const classes = useStyles();

  return (
    <>
      <FormControl component="fieldset" className={classes.radioButtonForm}>
        <RadioGroup
          row
          aria-label={name}
          name={name}
          value={state}
          onChange={(event) => {
            setState(parseInt(event.target.value, 10));
          }}
        >
          {options.map((option) => {
            return (
              <FormControlLabel
                key={option.display}
                value={option.value}
                control={<Radio color="primary" size="small" />}
                label={option.display}
                labelPlacement="end"
              />
            );
          })}
        </RadioGroup>
      </FormControl>
    </>
  );
};

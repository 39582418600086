import React, { useContext } from "react";
import ActiveFilterItem from "./ActiveFilterItem";
import { filterContext } from "../../../context/filterContext";
import { REMOVE_DESIGNER } from "../../../reducers/filterReducer";

const ActiveFilterDesigner = () => {
  const {
    dispatch,
    state: { designerIds },
  } = useContext(filterContext);

  const removeAction = (actionType, payload) => {
    dispatch({ type: actionType, payload: payload });
  };

  return (
    <>
      {designerIds
        ? designerIds.map((it, index) => {
            return (
              <ActiveFilterItem
                key={index}
                label={it.name}
                type={REMOVE_DESIGNER}
                payload={it.id}
                remove={removeAction}
              ></ActiveFilterItem>
            );
          })
        : null}
    </>
  );
};

export default ActiveFilterDesigner;

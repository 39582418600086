import React, { memo } from "react";
import ProductItem from "./ProductItem";

const ProductList = ({ data }) => {
  return data.map((it, index) => {
    return <ProductItem key={index} item={it} />;
  });
};

export default memo(ProductList);

import apiClient from "../../api/client";

const useHeaderSettings = () => {
  const setHeaderToken = (authToken) => {
    apiClient.headers["Authorization"] = "";
    delete apiClient.headers["Authorization"];

    if (authToken) {
      apiClient.headers["Authorization"] = `Bearer ${authToken}`;
    }
  };

  const clearHeaderToken = () => {
    apiClient.headers["Authorization"] = "";
    delete apiClient.headers["Authorization"];
  };

  return { setHeaderToken, clearHeaderToken };
};

export default useHeaderSettings;

import React, { useState, useContext, useEffect } from "react";
import {
  makeStyles,
  Paper,
  Typography,
  Grid,
  TextField,
  InputAdornment,
} from "@material-ui/core";
import HighlightOffRoundedIcon from "@material-ui/icons/HighlightOffRounded";
import { filterContext } from "../../../context/filterContext";
import {
  CLEAR_BY_QUERY,
  CLEAR_BY_VITKAC_URL,
} from "../../../reducers/filterReducer";

const useStyles = makeStyles((theme) => ({
  root: theme.filterPaper,
  labels: theme.datePriceLabels,
  divider: theme.divider,
  inputField: {
    ...theme.inputField,
    ...theme.inputClearButton,
  },
}));

const SearchFilter = () => {
  const classes = useStyles();
  const [urlQuery, setUrlQuery] = useState("");
  const [productQuery, setProductQuery] = useState("");

  const {
    dispatch,
    state: { searchByQuery, searchByVitkacUrl },
  } = useContext(filterContext);

  useEffect(() => {
    setUrlQuery(searchByVitkacUrl);
    setProductQuery(searchByQuery);
  }, [searchByQuery, searchByVitkacUrl]);

  const handleUrlQuery = (value) => {
    setUrlQuery(value);
  };

  const handleProductQuery = (value) => {
    setProductQuery(value);
  };

  return (
    <Paper className={classes.root}>
      <Typography variant="h3">Wyszukiwarka</Typography>
      <Grid container className={classes.labels}>
        <Grid item xs={12} style={{ marginBottom: 10 }}>
          <TextField
            key="query-for-url"
            label="Wpisz URL"
            value={urlQuery}
            variant="outlined"
            onChange={(event) => handleUrlQuery(event.target.value)}
            name="queryUrl"
            className={classes.inputField}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <HighlightOffRoundedIcon
                    style={{ color: "white" }}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      dispatch({ type: CLEAR_BY_VITKAC_URL });
                    }}
                  />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            key="query-for-product"
            label="Wpisz nazwę produktu"
            value={productQuery}
            variant="outlined"
            onChange={(event) => handleProductQuery(event.target.value)}
            name="queryName"
            className={classes.inputField}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <HighlightOffRoundedIcon
                    style={{ color: "white" }}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      dispatch({ type: CLEAR_BY_QUERY });
                    }}
                  />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default SearchFilter;

import React, { useState, useEffect } from "react";
import App from "./App";
import AuthContext from "./context/authContext";
import LoginPage from "./pages/Auth/LoginPage";
import { checkRefreshToken } from "./api/auth";
import authStorage from "./storage/authStorage";
import jwtDecode from "jwt-decode";
import useHeaderSettings from "./hooks/auth/useHeaderSettings";
import ReloadIndicator from "./components/ReloadIndicator";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { materialTheme } from "./style/materialTheme";
import { ThemeProvider } from "styled-components";
import AlertContainer from "./components/AlertContainer";

const Wrapper = () => {
  const [user, setUser] = useState();
  const [isReady, setIsReady] = useState(false);
  const { setHeaderToken } = useHeaderSettings();

  const refreshToken = async () => {
    const result = await checkRefreshToken();

    if (result.ok) {
      const { token } = result.data;
      authStorage.storeToken(token);
      const user = jwtDecode(token);
      setUser(user);
    } else {
      authStorage.clearToken();
      setUser(null);
    }
    setIsReady(true);
  };

  useEffect(() => {
    const token = authStorage.getToken();

    if (token) {
      setHeaderToken(token);
      refreshToken();
    } else {
      setUser(null);
      setIsReady(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!isReady) return <ReloadIndicator />;

  return (
    <>
      <MuiThemeProvider theme={materialTheme}>
        <ThemeProvider theme={materialTheme}>
          <AlertContainer />
          <AuthContext.Provider value={{ user, setUser }}>
            {user ? <App /> : <LoginPage />}
          </AuthContext.Provider>
        </ThemeProvider>
      </MuiThemeProvider>
    </>
  );
};

export default Wrapper;

import React from "react";
import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import LinearProgress from "@material-ui/core/LinearProgress";
import Skeleton from "@material-ui/lab/Skeleton";

const useStyles = makeStyles((theme) => ({
  placeholderPaper: theme.placeholderPaper,
  photo: {
    margin: "5px 0 0 15px",
  },
  title: {
    marginTop: "10px",
    marginLeft: "15px",
  },
  textSkeleton: {
    transform: "scale(1, 0.7)",
    "&:first-of-type": {
      marginTop: "3px",
    },
  },
}));

const Wrapper = styled.div`
  width: 100%;
  margin: 20px 0px;
`;

const ComponentIndicator = (props) => {
  const classes = useStyles();
  return (
    <Wrapper>
      <Paper className={classes.placeholderPaper}>
        <div>
          <LinearProgress />
          <Skeleton
            animation="wave"
            variant="text"
            width="calc(100% - 30px)"
            height="35px"
            className={classes.title}
          />

          <Grid container direction="row" spacing={2}>
            <Grid item className={classes.photo}>
              <Skeleton
                animation="wave"
                variant="rect"
                width="75px"
                height="110px"
              />
            </Grid>
            <Grid item xs>
              <Skeleton
                animation="wave"
                variant="text"
                width="calc(100% - 15px)"
                height="55px"
                classes={{ text: classes.textSkeleton }}
              />
              <Skeleton
                animation="wave"
                variant="text"
                width="calc(100% - 15px)"
                height="55px"
                classes={{ text: classes.textSkeleton }}
              />
            </Grid>
          </Grid>

          <Skeleton
            animation="wave"
            variant="text"
            width="calc(100% - 30px)"
            height="35px"
            className={classes.title}
          />
        </div>
      </Paper>
    </Wrapper>
  );
};

export default ComponentIndicator;

import React, { useRef, useContext, useState } from "react";
import styled from "styled-components";
import { Grid } from "@material-ui/core";
import PriceFilter from "./FiltersComponents/PriceFilter";
import SearchFilter from "./FiltersComponents/SearchFilter";
import CarryOverFilter from "./FiltersComponents/CarryOverFilter";
import MissingLinks from "./FiltersComponents/MissingLinks";
import FilterActions from "./FilterActions";
import { filterContext } from "../../context/filterContext";
import { SET_FORM } from "../../reducers/filterReducer";
import DesignerFilter from "./FiltersComponents/DesignerFilter";
import SeasonFilter from "./FiltersComponents/SeasonFilter";
import CategoryFilter from "./FiltersComponents/CategoryFilter";
import { tgExportCsv } from "../../api/towarGrupowanie";
import useParamsState from "../../hooks/useParams";
import useAlert from "../../hooks/useAlert";

const MainContainer = styled("div")`
  display: flex;
  flex-direction: column;
`;

const FiltersContent = ({ refToScroll }) => {
  const formRef = useRef(null);
  const { dispatch } = useContext(filterContext);
  const [openModalCsv, setOpenModalCsv] = useState(false);
  const [isLoadingCsv, setIsLoadingCsv] = useState(false);
  const { getParams } = useParamsState();
  const { error } = useAlert();

  const submitForm = () => {
    if (refToScroll && refToScroll.current) {
      refToScroll.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }

    const filtersObj = prepareFilterObject();

    dispatch({ type: SET_FORM, payload: { ...filtersObj, page: 1 } });
  };

  const handleCsvApi = async () => {
    setIsLoadingCsv(true);

    const filtersObj = prepareFilterObject();
    const params = getParams(filtersObj);

    const result = await tgExportCsv(params);

    if (!result.ok) {
      handleCloseModal();
      error("Błąd podczas generowania pliku csv. Spróbuj ponownie");
      return null;
    }

    return result.data;
  };

  const handleCloseModal = () => {
    setIsLoadingCsv(false);
    setOpenModalCsv(false);
  };

  const prepareFilterObject = () => {
    const form = formRef.current.elements;

    return {
      priceFrom: form.priceFrom ? form.priceFrom.value : "",
      priceTo: form.priceTo ? form.priceTo.value : "",
      searchByVitkacUrl: form.queryUrl ? form.queryUrl.value : "",
      searchByQuery: form.queryName ? form.queryName.value : "",
      isCo: form.isCo ? form.isCo.value : "",
      missingShopUrlSid: form.missingLinks ? form.missingLinks.value : "",
      seasonIds:
        form.seasons && form.seasons.value
          ? JSON.parse(form.seasons.value)
          : "",
      designerIds:
        form.designers && form.designers.value
          ? JSON.parse(form.designers.value)
          : "",
      categoryIds: form.category ? JSON.parse(form.category.value) : "",
    };
  };

  return (
    <MainContainer>
      <form ref={formRef}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <CategoryFilter />
          </Grid>
          <Grid item xs={6}>
            <PriceFilter />
          </Grid>
          <Grid item xs={6}>
            <SearchFilter />
          </Grid>
          <Grid item xs={6}>
            <CarryOverFilter />
          </Grid>
          <Grid item xs={6}>
            <MissingLinks />
          </Grid>
          <Grid item xs={6}>
            <DesignerFilter />
          </Grid>
          <Grid item xs={6}>
            <SeasonFilter />
          </Grid>
        </Grid>

        <FilterActions
          handleCloseModal={handleCloseModal}
          onSearch={submitForm}
          handleCsvApi={handleCsvApi}
          isLoadingCsv={isLoadingCsv}
          setOpenModal={setOpenModalCsv}
          openModal={openModalCsv}
        />
      </form>
    </MainContainer>
  );
};

export default FiltersContent;

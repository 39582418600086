import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import LogoutIcon from "@material-ui/icons/ExitToApp";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import authStorage from "../../storage/authStorage";
import useAuth from "../../hooks/auth/useAuth";

const StyledAppBar = styled(AppBar)`
  ${({ theme }) => `
    background-color: ${theme.palette.common.white} !important;
    box-shadow: ${"0 -6px 23px 0 rgba(146,146,146,0.15)"} !important;
    border-bottom: 1px solid ${theme.palette.border};

    & .headerTitle {
      margin-right: 2vw;
      margin-left: 5px;
    }

    & .toolbar {
      min-height: ${theme.headerHeight};
      height: ${theme.headerHeight};
      padding-left: 5px;
      paddingLeft: 5px;
    }

    & .menu {
      height: 100%;
    }

    & .menuItem {
      margin-left: 25px;
      flex-grow: 1;
      color: black;
      text-transform: uppercase;
      display: flex;
      height: 100%;
    }

    & .link {
      text-decoration: none;
      padding-top: 12px;
      color: black;
    }

    & .activeLink {
      border-top: 2px solid #000 !important;
      padding-top: 10px;
    }

    & .logout {
      color: ${theme.palette.common.black};
      margin-right: 100px;
    }

    & .logoutIcon {
      margin-right: 5px;
    }
  `}
`;

export const AppMenu = () => {
  const user = authStorage.getUser();
  const { logout } = useAuth();

  return (
    <StyledAppBar position="fixed" id="header">
      <Toolbar className={"toolbar"}>
        <div>
          <img src={process.env.PUBLIC_URL + "/vitkac-logo.png"} alt="" />
        </div>
        <Typography variant="caption" className={"headerTitle"}>
          System porównywania cen
        </Typography>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="stretch"
          className={"menu"}
        >
          <Grid item>
            <Typography variant="h6" className={"menuItem"}>
              <NavLink to="/" activeClassName={"activeLink"} className={"link"}>
                Produkty
              </NavLink>
            </Typography>
          </Grid>
        </Grid>

        <Button color="inherit" className={"logout"} onClick={logout}>
          <LogoutIcon className={"logoutIcon"}></LogoutIcon>
          Wyloguj - {user.email}
        </Button>
      </Toolbar>
    </StyledAppBar>
  );
};

export default AppMenu;

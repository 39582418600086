import apiClient from "./client";

export const getTowarGrupowanie = (filters = {}) =>
  apiClient.get("/tg/list/complete", filters);

export const updateShopUrl = (prefix, tgId, url) =>
  apiClient.put("/tg/shop_url/" + prefix + "/" + tgId, { url: url });

export const tgExportCsv = (filters = {}) =>
  apiClient.get("/tg/export_csv", filters);

export const checkIsTgCsvReady = (name) =>
  apiClient.get("tg/check_csv/" + name);

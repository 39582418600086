import React, { useState, useContext, useEffect } from "react";
import { Paper, Typography, makeStyles, Grid } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { filterContext } from "../../../context/filterContext";

const useStyles = makeStyles((theme) => ({
  root: theme.filterPaper,
}));

const farfetchAlias = "farfetch";
const matchesFashionAlias = "matchesfashion";
const netAPorterAlias = "netaporter";
const mrPorterAlias = "mrporter";
const myTheresaAlias = "mytheresa";

const MissingLinks = () => {
  const classes = useStyles();
  const [selected, setSelected] = useState([]);

  const {
    state: { missingShopUrlSid },
  } = useContext(filterContext);

  useEffect(() => {
    if (missingShopUrlSid) {
      setSelected(missingShopUrlSid.split(","));
    } else {
      setSelected([]);
    }
  }, [missingShopUrlSid]);

  const handleOption = (name) => {
    let newArr = [];

    if (selected.includes(name)) {
      newArr = selected.filter((it) => it !== name);
    } else {
      newArr = [...selected];
      newArr.push(name);
    }

    setSelected(newArr);
  };

  return (
    <Paper className={classes.root}>
      <Typography variant="h3" style={{ paddingBottom: 10 }}>
        Brakujące linki do sklepów
      </Typography>
      <input type="hidden" value={selected} name="missingLinks" />
      <div style={{ paddingLeft: 7 }}>
        <Grid container item xs={12} spacing={1}>
          <FormControlLabel
            control={
              <Checkbox
                checked={selected.includes(farfetchAlias)}
                onChange={(e) => {
                  e.preventDefault();
                  handleOption(farfetchAlias);
                }}
              />
            }
            label="Farfetch"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={selected.includes(matchesFashionAlias)}
                onChange={(e) => {
                  handleOption(matchesFashionAlias);
                }}
              />
            }
            label="Matches Fashion"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={selected.includes(netAPorterAlias)}
                onChange={(e) => {
                  handleOption(netAPorterAlias);
                }}
              />
            }
            label="Net-A-Porter"
          />
            <FormControlLabel
                control={
                    <Checkbox
                        checked={selected.includes(mrPorterAlias)}
                        onChange={(e) => {
                            handleOption(mrPorterAlias);
                        }}
                    />
                }
                label="Mr Porter"
            />
            <FormControlLabel
                control={
                    <Checkbox
                        checked={selected.includes(myTheresaAlias)}
                        onChange={(e) => {
                            handleOption(myTheresaAlias);
                        }}
                    />
                }
                label="My Theresa"
            />
        </Grid>
      </div>
    </Paper>
  );
};

export default MissingLinks;

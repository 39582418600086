import React, { useRef } from "react";
import FiltersPanel from "../components/Filters/FiltersPanel";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { FilterProvider } from "../context/filterContext";
import ProductPanel from "../components/Products/ProductPanel";
import ActiveFilters from "../components/Filters/ActiveFilters/ActiveFilters";

const ProductsPage = () => {
  const scrollRef = useRef(null);

  return (
    <HelmetProvider>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Produkty</title>
      </Helmet>
      <FilterProvider>
        <FiltersPanel refToScroll={scrollRef} />
        <div ref={scrollRef}>
          <ActiveFilters />
        </div>

        <ProductPanel />
      </FilterProvider>
    </HelmetProvider>
  );
};

export default ProductsPage;

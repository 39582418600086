import React, { useContext, useEffect, useState } from "react";
import { filterContext } from "../../context/filterContext";
import ProductList from "./ProductList";
import Pagination from "../Filters/FiltersComponents/Pagination";
import { getTowarGrupowanie } from "../../api/towarGrupowanie";
import useParamsState from "../../hooks/useParams";
import ComponentIndicator from "../ComponentIndicator";
import NoProductNotification from "../NoProductNotification";
import styled from "styled-components";

const ProductPanel = () => {
  const { state } = useContext(filterContext);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [totalItems, setTotalItems] = useState(0);
  const { getParams } = useParamsState();

  useEffect(() => {
    setIsLoading(true);

    const params = getParams(state);
    LoadTowarGrupowanie(params);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  const LoadTowarGrupowanie = async (parseState) => {
    const result = await getTowarGrupowanie(parseState);

    if (!result.ok) {
      return;
    }

    setTotalItems(result.data.totalItems);
    setData(result.data);
    setIsLoading(false);
  };

  if (isLoading)
    return [...Array(3)].map((it, index) => {
      return <ComponentIndicator key={index} />;
    });

  if (!data.result.length) return <NoProductNotification />;

  return (
    <>
      <TopWrapper>
        <TotalItems totalItems={totalItems} />
        <Pagination totalPages={data.totalPages} />
      </TopWrapper>

      <ProductList data={data.result} />
      <Pagination totalPages={data.totalPages} />
    </>
  );
};

export default ProductPanel;

const TotalItems = ({ totalItems }) => {
  return <TotalItemsWrapper>{totalItems} produktów</TotalItemsWrapper>;
};

const TotalItemsWrapper = styled.div`
  margin-top: 20px;
  width: 100%;
  font-size: 14px;
  color: #808080;
`;

const TopWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const SET_FORM = "set_form";
export const CLEAR_FILTERS = "clear_filters";
export const CLEAR_PRICE_FROM = "clear_price_from";
export const CLEAR_PRICE_TO = "clear_price_to";
export const CLEAR_BY_VITKAC_URL = "clear_by_vitkac_url";
export const CLEAR_BY_QUERY = "clear_by_query";
export const CLEAR_CATEGORY = "clear_category";
export const CLEAR_IS_CO = "clear_is_co";
export const REMOVE_DESIGNER = "remove_designer";
export const REMOVE_SEASON = "remove_season";
export const REMOVE_MISSING_LINKS = "remove_missing_links";

// pagination
export const SET_PAGE = "set_page";
export const SET_LIMIT_ON_PAGE = "set_limit_on_page";

export const initialValue = {
  priceFrom: "",
  priceTo: "",
  isCo: 2,
  searchByQuery: "",
  searchByVitkacUrl: "",
  missingShopUrlSid: "",
  categoryIds: "",
  designerIds: "",
  seasonIds: "",
  page: 1,
  limit: 20,
};

export const reducer = (state, action) => {
  switch (action.type) {
    case SET_FORM:
      return {
        ...state,
        ...action.payload,
      };
    case SET_PAGE:
      return {
        ...state,
        page: action.payload,
      };
    case SET_LIMIT_ON_PAGE:
      return {
        ...state,
        limit: action.payload,
      };
    case CLEAR_FILTERS:
      return {
        ...initialValue,
      };
    case CLEAR_PRICE_FROM:
      return {
        ...state,
        page: 1,
        priceFrom: initialValue.priceFrom,
      };
    case CLEAR_PRICE_TO:
      return {
        ...state,
        page: 1,
        priceTo: initialValue.priceTo,
      };
    case CLEAR_BY_VITKAC_URL:
      return {
        ...state,
        page: 1,
        searchByVitkacUrl: initialValue.searchByVitkacUrl,
      };
    case CLEAR_BY_QUERY:
      return {
        ...state,
        page: 1,
        searchByQuery: initialValue.searchByQuery,
      };
    case CLEAR_CATEGORY:
      return {
        ...state,
        page: 1,
        categoryIds: initialValue.categoryIds,
      };
    case CLEAR_IS_CO:
      return {
        ...state,
        page: 1,
        isCo: initialValue.isCo,
      };
    case REMOVE_DESIGNER:
      const newArr = state.designerIds.filter((it) => it.id !== action.payload);

      return {
        ...state,
        page: 1,
        designerIds: newArr.length ? newArr : initialValue.designerIds,
      };
    case REMOVE_SEASON:
      const newArrSeasons = state.seasonIds.filter(
        (it) => it.id !== action.payload
      );

      return {
        ...state,
        page: 1,
        seasonIds: newArrSeasons.length
          ? newArrSeasons
          : initialValue.seasonIds,
      };
    case REMOVE_MISSING_LINKS:
      const links = state.missingShopUrlSid.split(",");
      let newArrLinks = links;

      if (links.includes(action.payload)) {
        newArrLinks = links.filter((it) => it !== action.payload);
      }

      return {
        ...state,
        missingShopUrlSid: newArrLinks.join(","),
      };

    default:
      return state;
  }
};

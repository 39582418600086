import React, { useContext } from "react";
import ActiveFilterItem from "./ActiveFilterItem";
import { filterContext } from "../../../context/filterContext";
import { REMOVE_MISSING_LINKS } from "../../../reducers/filterReducer";

const ActiveFilterMissingLinks = () => {
  const {
    dispatch,
    state: { missingShopUrlSid },
  } = useContext(filterContext);

  const removeAction = (actionType, payload) => {
    dispatch({ type: actionType, payload: payload });
  };

  return (
    <>
      {missingShopUrlSid
        ? missingShopUrlSid.split(",").map((it, index) => {
            return (
              <ActiveFilterItem
                key={index}
                label={`Brakujący link: ${it}`}
                type={REMOVE_MISSING_LINKS}
                payload={it}
                remove={removeAction}
              ></ActiveFilterItem>
            );
          })
        : null}
    </>
  );
};

export default ActiveFilterMissingLinks;

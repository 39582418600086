import { useContext } from "react";
import JwtDecode from "jwt-decode";
import authStorage from "./../../storage/authStorage";
import AuthContext from "../../context/authContext";
import useHeaderSettings from "./useHeaderSettings";

const useAuth = () => {
  const { user, setUser } = useContext(AuthContext);
  const { setHeaderToken, clearHeaderToken } = useHeaderSettings();

  const logIn = (authToken) => {
    const user = JwtDecode(authToken);
    authStorage.storeToken(authToken);
    setUser(user);
    setHeader(authToken);
  };

  const logout = () => {
    setUser(null);
    authStorage.clearToken();
    clearHeaderToken();
  };

  const setHeader = (authToken) => {
    setHeaderToken(authToken);
  };

  return { user, logIn, logout, setHeader };
};

export default useAuth;

import React, { useReducer } from "react";
import { initialValue, reducer } from "../reducers/filterReducer";

export const filterContext = React.createContext(initialValue);

export const FilterProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialValue);

  return (
    <filterContext.Provider value={{ state, dispatch }}>
      {children}
    </filterContext.Provider>
  );
};

import React, { useContext } from "react";
import { Button } from "@material-ui/core";
import styled from "styled-components";
import { filterContext } from "../../context/filterContext";
import { CLEAR_FILTERS } from "../../reducers/filterReducer";
import CsvModal from "./CsvModal";

const MainContainer = styled("div")`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const FilterActions = ({
  handleCloseModal,
  onSearch,
  handleCsvApi,
  isLoadingCsv,
  setOpenModal,
  openModal,
}) => {
  const { dispatch } = useContext(filterContext);

  const clearFilters = () => {
    dispatch({ type: CLEAR_FILTERS });
  };
  return (
    <>
      <MainContainer>
        <Button variant="contained" color="primary" onClick={onSearch}>
          Szukaj
        </Button>
        <Button
          variant="contained"
          color="secondary"
          style={{ marginLeft: "10px" }}
          onClick={() => clearFilters()}
        >
          Wyczyść filtry
        </Button>
        <Button
          variant="contained"
          color="default"
          onClick={() => setOpenModal(true)}
          style={{ marginLeft: "40px", width: "100px" }}
        >
          Pobierz Csv
        </Button>
        <CsvModal
          isLoadingCsv={isLoadingCsv}
          open={openModal}
          handleClose={handleCloseModal}
          handleCsvApi={handleCsvApi}
        />
      </MainContainer>
    </>
  );
};

export default FilterActions;

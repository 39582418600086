import React from "react";
import styled from "styled-components";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Typography } from "@material-ui/core";
import FiltersContent from "./FiltersContent";

const MainContainer = styled("div")`
  width: 100%;

  & .typography {
    text-transform: uppercase;
  }
`;

const FiltersPanel = ({ refToScroll }) => {
  return (
    <MainContainer>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
        >
          <Typography variant="h3" className="typography">
            Filtrowanie
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <FiltersContent refToScroll={refToScroll} />
        </AccordionDetails>
      </Accordion>
    </MainContainer>
  );
};

export default FiltersPanel;

import React, { useState } from "react";
import { makeStyles, Paper, Typography, Link } from "@material-ui/core";
import styled from "styled-components";
import LinksPanel from "./LinksPanel";
import LinkIcon from "@material-ui/icons/Link";

const useStyles = makeStyles((theme) => ({
  root: theme.filterPaper,
}));

const MainContainer = styled.div`
  display: grid;
  grid-template-columns: 10% 35% auto;
  align-items: center;
`;

const FotoWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

const Foto = styled.img`
  height: 145px;
`;

const PhotoPreviev = styled.img`
  visibility: ${(props) => (props.isShow ? "visible" : "hidden")};
  position: absolute;
  border-radius: 3px;
  border: 1px solid #a9a9a9;
  top: -100%;
  left: 115px;
  width: 300px;
  height: auto;
  /* transition: 1s ease-in-out; */
  z-index: 999;
`;

const DescrtiptionContainer = styled.div`
  flex-direction: column;
  padding-left: 15px;
`;

const DescriptionItem = styled.div`
  display: grid;
  grid-template-columns: 40% auto;
  text-transform: uppercase;
  margin-bottom: 5px;
  padding-right: 20px;
  font-size: 14px;
`;

const PriceWrapper = styled.div`
  position: relative;
  font-size: 14px;

  :hover {
    cursor: ${(props) => (props.isPrices ? "pointer" : "default")};
  }
`;

const PriceInfoWrapper = styled.div`
  position: absolute;
  top: 35px;
  z-index: 9;
  width: 150px;
  height: auto;
  border-radius: 10px;
  border: 1px solid #e8e8e8;
  background-color: #f5f5f5;
  visibility: ${(props) => (props.isShow ? "visible" : "hidden")};
`;

const RowPriceInfo = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: row;
  gap: 5px;
`;

const ProductItem = (props) => {
  const classes = useStyles();
  const [isShowPreview, setIsShowPreview] = useState(false);

  const {
    photos,
    category,
    name,
    shopUrl,
    id,
    designer,
    symbol,
    vitkacUrl,
    price,
    priceCompetition,
  } = props.item;
  return (
    <Paper className={classes.root} style={{ marginBottom: "1px" }}>
      <MainContainer>
        <FotoWrapper>
          <Foto
            src={photos && photos.photo ? photos.photo : null}
            alt={"photo"}
            onMouseEnter={() => setIsShowPreview(true)}
            onMouseLeave={() => setIsShowPreview(false)}
          />
          <PhotoPreviev
            src={photos && photos.photo ? photos.photo : null}
            isShow={isShowPreview}
          />
        </FotoWrapper>

        <DescrtiptionContainer>
          <DescriptionItem>
            <Typography variant="subtitle2">Nazwa</Typography>
            <Typography variant="body1">{name}</Typography>
          </DescriptionItem>
          <DescriptionItem>
            <Typography variant="subtitle2">Projektant</Typography>
            <Typography variant="body1">
              {designer && designer.name
                ? designer.name
                : "---BRAK PROJEKTANTA---"}
            </Typography>
          </DescriptionItem>
          <DescriptionItem>
            <Typography variant="subtitle2">Symbol</Typography>
            <Typography variant="body1">
              {symbol.length ? symbol : "---BRAK SYMBOLU---"}
            </Typography>
          </DescriptionItem>
          <DescriptionItem>
            <Typography variant="subtitle2">Kategoria</Typography>
            <Typography variant="body1">
              {category ? category.fullPath : "---BRAK KATEGORI---"}
            </Typography>
          </DescriptionItem>
          <DescriptionItem>
            <Typography variant="subtitle2">Pokaż na stronie</Typography>
            <Typography variant="body1">
              <Link
                href={vitkacUrl}
                target="_blank"
                underline="none"
                color="primary"
              >
                Vitkac <LinkIcon style={{ fontSize: 16, opacity: 0.5 }} />
              </Link>
            </Typography>
          </DescriptionItem>
          <DescriptionItem>
            <Typography variant="subtitle2">Cena bazowa</Typography>
            {/* <Typography variant="body1">---BRAK CENY---</Typography> */}
            <ShowPrice price={price} />
          </DescriptionItem>
        </DescrtiptionContainer>

        <div>
          <LinksPanel shopUrl={shopUrl} tgId={id} prices={priceCompetition} />
        </div>
      </MainContainer>
    </Paper>
  );
};

export default ProductItem;

const ShowPrice = ({ price }) => {
  const [isShow, setIsShow] = useState(false);

  const pricePln = price.length
    ? price.filter((it) => it.currencyCode === "PLN")[0]
    : null;

  const pricesWithoutPln = price.length
    ? price.filter((it) => it.currencyCode !== "PLN")
    : null;

  return (
    <PriceWrapper
      onMouseEnter={() => setIsShow(price.length ?? true)}
      onMouseLeave={() => setIsShow(false)}
      isPrices={price.length ? true : false}
    >
      {pricePln ? `${pricePln.price.toFixed(2)} PLN` : "---BRAK CENY---"}
      <PriceInfoWrapper isShow={isShow}>
        {pricesWithoutPln?.map((it, index) => {
          return (
            <RowPriceInfo key={index}>
              <div>{`${it.currencyCode}:`}</div>
              <div>{it.price}</div>
            </RowPriceInfo>
          );
        })}
      </PriceInfoWrapper>
    </PriceWrapper>
  );
};

import React, { useState, useEffect } from "react";
import styled from "styled-components";
import {
  makeStyles,
  TextField,
  InputAdornment,
  Link,
  Tooltip,
} from "@material-ui/core";
import HighlightOffRoundedIcon from "@material-ui/icons/HighlightOffRounded";
import OpenInNew from "@material-ui/icons/OpenInNew";
import Inventory from "@material-ui/icons/AssignmentReturned";
import CheckCircle from "@material-ui/icons/CheckCircleOutlineRounded";
import Euro from "@material-ui/icons/Euro";
import { updateShopUrl } from "../../api/towarGrupowanie";

const useStyles = makeStyles((theme) => ({
  root: theme.filterPaper,
  labels: theme.datePriceLabels,
  divider: theme.divider,
  inputField: {
    ...theme.inputField,
    ...theme.inputClearButton,
  },
}));

const MainWrapper = styled.div`
  display: grid;
  grid-template-rows: repeat(3, auto);
  gap: 10px;
`;

const ItemWrapper = styled.div`
  display: grid;
  grid-template-columns: 35px auto 110px;
  align-items: center;
`;

const PriceWrapper = styled.div`
  position: relative;
  height: 30px;
  width: 30px;
  background-color: #dcdcdc;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
  opacity: ${(props) => (props.isShow ? 1 : 0.5)};

  :hover {
    background-color: ${(props) => (props.isShow ? "#c0c0c0" : "#dcdcdc")};
    cursor: ${(props) => (props.isShow ? "pointer" : "default")};
  }
`;

const PriceInfoWrapper = styled.div`
  position: absolute;
  top: 35px;
  z-index: 9;
  width: 150px;
  height: auto;
  border-radius: 10px;
  border: 1px solid #e8e8e8;
  background-color: #f5f5f5;
  visibility: ${(props) => (props.isShow ? "visible" : "hidden")};
`;

const RowPriceInfo = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: row;
  gap: 5px;
`;

const ActionWrapper = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  justify-items: end;
  align-items: center;
`;

const Icon = styled.div`
  width: 40px;
  height: 40px;
  border: 1px solid #979797;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: ${(props) => (props.isValid ? 1 : 0.5)};

  :hover {
    border: ${(props) => (props.isValid ? "1px solid black" : "")};
    cursor: pointer;
  }
`;

const sidFarfetch = "farfetch";
const sidMatchesFashion = "matchesfashion";
const sidNetAPorter = "netaporter";
const sidMrPorter = 'mrporter';
const sidMyTheresa = 'mytheresa';

const LinksPanel = ({ shopUrl, tgId, prices }) => {
  const classes = useStyles();
  const [arrLinks, setArrLinks] = useState([]);

  useEffect(() => {
    const farfetchUrl = shopUrl.length
      ? shopUrl.filter((it) => it.shop.sid === sidFarfetch)
      : [];
    const farfetchPrices = prices.length
      ? prices.filter((it) => it.shopSid === sidFarfetch)
      : [];

    const netaporterUrl = shopUrl.length
      ? shopUrl.filter((it) => it.shop.sid === sidNetAPorter)
      : [];
    const netaporterPrices = prices.length
      ? prices.filter((it) => it.shopSid === sidNetAPorter)
      : [];

    const matchesfashionUrl = shopUrl.length
      ? shopUrl.filter((it) => it.shop.sid === sidMatchesFashion)
      : [];
    const matchesfashionPrices = prices.length
      ? prices.filter((it) => it.shopSid === sidMatchesFashion)
      : [];

    const mrporterUrl = shopUrl.length
        ? shopUrl.filter((it) => it.shop.sid === sidMrPorter)
        : [];
    const mrporterPrices = prices.length
        ? prices.filter((it) => it.shopSid === sidMrPorter)
        : [];

    const mytheresaUrl = shopUrl.length
        ? shopUrl.filter((it) => it.shop.sid === sidMyTheresa)
        : [];
    const mytheresaPrices = prices.length
        ? prices.filter((it) => it.shopSid === sidMyTheresa)
        : [];

    const arr = [
      {
        name: "Farfetch",
        url: farfetchUrl.length ? farfetchUrl[0].url : "",
        urlValid: farfetchUrl.length ? !farfetchUrl[0].badUrl : true,
        wwwPrefix: "farfetch",
        sid: sidFarfetch,
        prices: farfetchPrices.length ? farfetchPrices[0].values : [],
      },
      {
        name: "Matches Fashion",
        url: matchesfashionUrl.length ? matchesfashionUrl[0].url : "",
        urlValid: matchesfashionUrl.length
          ? !matchesfashionUrl[0].badUrl
          : true,
        wwwPrefix: "matchesfashion",
        sid: sidMatchesFashion,
        prices: matchesfashionPrices.length
          ? matchesfashionPrices[0].values
          : [],
      },
      {
        name: "Net A Porter",
        url: netaporterUrl.length ? netaporterUrl[0].url : "",
        urlValid: netaporterUrl.length ? !netaporterUrl[0].badUrl : true,
        wwwPrefix: "net-a-porter",
        sid: sidNetAPorter,
        prices: netaporterPrices.length ? netaporterPrices[0].values : [],
      },
      {
        name: "Mr Porter",
        url: mrporterUrl.length ? mrporterUrl[0].url : "",
        urlValid: mrporterUrl.length ? !mrporterUrl[0].badUrl : true,
        wwwPrefix: "mrporter",
        sid: sidMrPorter,
        prices: mrporterPrices.length ? mrporterPrices[0].values : [],
      },
      {
        name: "My Theresa",
        url: mytheresaUrl.length ? mytheresaUrl[0].url : "",
        urlValid: mytheresaUrl.length ? !mytheresaUrl[0].badUrl : true,
        wwwPrefix: "mytheresa",
        sid: sidMyTheresa,
        prices: mytheresaPrices.length ? mytheresaPrices[0].values : [],
      },
    ];

    setArrLinks(arr);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleUrl = (urlQuery, item) => {
    const valid = isValidHttpUrl(urlQuery, item.wwwPrefix);

    if (valid) {
      updateLinkRequest(item.sid, urlQuery, item.wwwPrefix);
    } else {
      updateArr(urlQuery, item.wwwPrefix, valid);
    }
  };

  const updateLinkRequest = async (sid, url, prefix) => {
    updateArr(url, prefix, true);

    const result = await updateShopUrl(sid, tgId, url);

    if (!result.ok) {
      updateArr(url, prefix, false);
      return;
    }
  };

  const updateArr = (urlQuery, wwwPrefix, valid) => {
    const newArr = arrLinks.map((it) => {
      if (it.wwwPrefix === wwwPrefix) {
        it.url = urlQuery;
        it.urlValid = urlQuery.length ? valid : true;
      }

      return it;
    });

    setArrLinks(newArr);
  };

  const isValidHttpUrl = (urlQuery, prefix) => {
    try {
      new URL(urlQuery);
    } catch (_) {
      return false;
    }

    const string = `https://www.${prefix}.com`;

    return urlQuery.startsWith(string);
  };

  return (
    <>
      <MainWrapper>
        {arrLinks.map((it, index) => {
          return (
            <ItemWrapper key={index}>
              <PriceCompetition prices={it.prices} />

              <TextField
                error={it.urlValid ? false : true}
                key={index}
                label={it.name}
                value={it.url}
                variant="outlined"
                onChange={(event) => handleUrl(event.target.value, it)}
                // name={"Wprowadź url"}
                className={classes.inputField}
                style={{
                  backgroundColor:
                    it.urlValid && it.url.length
                      ? "#e6ffe6"
                      : !it.urlValid
                      ? "#ffd6cc"
                      : "",
                }}
                id="validation-outlined-input"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {it.urlValid && it.url.length ? (
                        <CheckCircle
                          style={{
                            color: "green",
                          }}
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            // handleUrlQuery("");
                          }}
                        />
                      ) : (
                        <HighlightOffRoundedIcon
                          style={{ color: !it.urlValid ? "red" : "white" }}
                          onClick={(e) => {
                            // handleUrlQuery("");
                          }}
                        />
                      )}
                    </InputAdornment>
                  ),
                }}
              />
              <ActionWrapper>
                <Tooltip title={"Wklej"}>
                  <Icon
                    isValid={true}
                    onClick={() => {
                      navigator.clipboard
                        .readText()
                        .then((res) => handleUrl(res, it));
                    }}
                  >
                    <Inventory />
                  </Icon>
                </Tooltip>

                <Tooltip title={"Idź na stronę"}>
                  {it.url.length ? (
                    <Link href={it.url} target="_blank">
                      <Icon isValid={true}>
                        <OpenInNew />
                      </Icon>
                    </Link>
                  ) : (
                    <Icon isValid={false}>
                      <OpenInNew />
                    </Icon>
                  )}
                </Tooltip>
              </ActionWrapper>
            </ItemWrapper>
          );
        })}
      </MainWrapper>
    </>
  );
};

export default LinksPanel;

const PriceCompetition = ({ prices }) => {
  const [isShow, setIsShow] = useState(false);

  return (
    <PriceWrapper
      onMouseEnter={() => setIsShow(prices.length ?? true)}
      onMouseLeave={() => setIsShow(false)}
      isShow={prices.length ? true : false}
    >
      <Euro />
      <PriceInfoWrapper isShow={isShow}>
        {prices?.map((it, index) => {
          return (
            <RowPriceInfo key={index}>
              <div>{`${it.currencyCode}:`}</div>
              <div>{it.price}</div>
            </RowPriceInfo>
          );
        })}
      </PriceInfoWrapper>
    </PriceWrapper>
  );
};

import React from "react";
import Typography from "@material-ui/core/Typography";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
`;

const NoProductNotification = () => {
  return (
    <Wrapper>
      <Typography variant="h2">Brak wyników wyszukiwania</Typography>
    </Wrapper>
  );
};

export default NoProductNotification;

import React from "react";
import Dialog from "@material-ui/core/Dialog";
import { makeStyles } from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";
import { Button, CircularProgress } from "@material-ui/core";
import styled from "styled-components";
import { checkIsTgCsvReady } from "../../api/towarGrupowanie";
import useAlert from "../../hooks/useAlert";

const useStyles = makeStyles((theme) => ({
  root: {
    " & .MuiDialog-paperWidthSm": {
      maxWidth: "unset!important",
      minWidth: "660px",
    },
  },
  dialog: {
    padding: "0 12px",
    "& .MuiPaper-root": {
      boxShadow: "none!important",
    },
  },
}));

const ContentWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
`;

const IconWrapper = styled.div`
  justify-content: center;
  display: flex;
`;

const CsvModal = ({ open, handleClose, handleCsvApi, isLoadingCsv }) => {
  const classes = useStyles();
  const { error } = useAlert();
  let timeout;

  const handleCsv = async () => {
    const result = await handleCsvApi();

    if (result && result.hash) {
      checkFileReady(result.hash);
    }
  };

  const checkFileReady = async (hash) => {
    const result = await checkIsTgCsvReady(hash);

    if (result.data && result.data.exist === false) {
      timeout = setTimeout(() => checkFileReady(hash), 5000);
      return;
    }

    clearTimeout(timeout);

    try {
      const type = result.headers["content-type"];
      const blob = new Blob([result.data], { type: type, encoding: "UTF-8" });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "products";

      link.click();
    } catch (err) {
      error("Błąd podczas generowania pliku csv. Spróbuj ponownie");
    } finally {
      handleClose();
    }
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="dialog-title"
        className={classes.root}
      >
        <ContentWrapper>
          <IconWrapper>
            <img
              src={process.env.PUBLIC_URL + "/csv.png"}
              alt=""
              style={{ width: "150px", height: "150px" }}
            />
          </IconWrapper>

          <Button
            variant="contained"
            onClick={handleCsv}
            style={{ width: "150px" }}
          >
            {isLoadingCsv ? (
              <CircularProgress size={20} style={{ color: "white" }} />
            ) : (
              "Pobierz plik CSV"
            )}
          </Button>
        </ContentWrapper>

        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Zamknij
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CsvModal;

import React from "react";
import styled from "styled-components";
import { Helmet, HelmetProvider } from "react-helmet-async";
import LoginPageForm from "../../components/Auth/LoginPageForm";

const LoginPageContainer = styled("div")`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fafafa;
`;

const LoginPage = () => {
  return (
    <HelmetProvider>
      <LoginPageContainer>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Logowanie</title>
        </Helmet>
        <LoginPageForm />
      </LoginPageContainer>
    </HelmetProvider>
  );
};

export default LoginPage;

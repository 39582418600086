import { createTheme } from "@material-ui/core/styles";

export const materialTheme = createTheme({
  divider: {
    width: "26px",
    margin: "0 12px",
    lineHeight: "21px",
  },
  inputField: {
    width: "100%",
  },
  inputClearButton: {
    backgroundColor: "#979797",
    transition: "background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    borderRadius: "4px",
    "&:hover": {
      backgroundColor: "black",
      transition: "background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    },
  },
  filterPaper: {
    margin: "0",
    padding: "12px 12px 20px",
    borderRadius: "3px",
    boxShadow: "0 1px 1px 1px rgba(146,146,146,0.45)",
  },
  filterTitle: {
    fontWeight: "400",
  },
  tablePaper: {
    margin: "0",
    backgroundColor: "white",
    borderRadius: "3px",
    // boxShadow: "0 1px 1px 1px rgba(146,146,146,0.45)",
    boxShadow:
      "0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12)",
  },
  chartPaper: {
    margin: "0",
    padding: "20px 5px 16px 18px",
    backgroundColor: "white",
    borderRadius: "3px",
    // boxShadow: "0 1px 1px 1px rgba(146,146,146,0.45)"
    boxShadow:
      "0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12)",
  },
  bestsellerPaper: {
    margin: "0",
    padding: 0,
    borderRadius: "1px",
    // boxShadow: "0 1px 1px 1px rgba(146,146,146,0.45)"
    boxShadow: "none",
  },
  placeholderPaper: {
    margin: "0",
    padding: "0 0 12px",
    borderRadius: "1px",
    // boxShadow: "0 1px 1px 1px rgba(146,146,146,0.45)"
    boxShadow:
      "0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12)",
  },
  datePriceLabels: {
    marginTop: "25px",
  },
  radioButtonForm: {
    marginTop: "-10px",
  },
  radioButton: {
    width: "20px",
    height: "20px",
  },
  headerHeight: "43px",
  leftMenuWidth: "52px",
  noMargin: {
    padding: "0!important",
    margin: "0!important",
  },
  noMarginY: {
    marginTop: "0!important",
    marginBottom: "0!important",
    paddingTop: "0!important",
    paddingBottom: "0!important",
  },
  noMarginRight: {
    marginRight: "0!important",
    paddingRight: "0!important",
  },
  deleteIcon: {
    height: "15px",
    width: "15px",
  },
  pre: {
    width: "100%",
    height: "350px",
    overflow: "auto",
    border: "1px solid rgba(0, 0, 0, 0.23)",
    borderRadius: "4px",
    padding: "12px 10px",
    boxSizing: "border-box",
    "&:hover": {
      border: "1px solid black",
    },
  },
  logsTextInput: {
    boxSizing: "border-box",
    height: "100%",
    borderRadius: "3px",
    border: "1px solid rgba(0, 0, 0, 0.23)",
    paddingLeft: "10px",
    width: "60px",
    textAlign: "center",
    "&:hover": {
      border: "1px solid black",
    },
    "&:focus": {
      outline: "none",
    },
  },
  paginationSelect: {
    input: {
      padding: "6px 12px 3px",
      height: "30px",
      fontSize: "0.95em",
    },
    notchedOutline: {
      borderColor: "rgba(132, 132, 132, 0.13)",
    },
  },
  palette: {
    divider: "rgba(158, 158, 158, 0.5)",
    border: "rgba(158, 158, 158, 0.2)",
    lightGray: "#cacaca",
    gray: "#f8f8f8",
    darkGray: "#eaeaea",
    primary: {
      main: "#000000",
    },
    secondary: {
      main: "#8c0000",
    },
  },
  typography: {
    fontFamily: [
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    body1: {
      fontSize: "0.88em",
      paddingTop: "2px",
    },
    body2: {
      fontSize: "0.9em",
      color: "#a0a0a0",
    },
    h2: {
      fontSize: "1.3em",
      fontWeight: 400,
      lineHeight: 1.5,
      margin: "0 0 15px",
    },
    h3: {
      padding: "0 0 0 5px",
      fontSize: "0.9rem",
    },
    h4: {
      fontSize: "1.2em",
      fontWeight: 500,
      lineHeight: 1.5,
      margin: "6px 0 6px 16px",
    },
    h5: {
      fontSize: "1em",
      fontWeight: 400,
      lineHeight: 1,
    },
    h6: {
      fontSize: "0.75em",
      fontWeight: 400,
    },
    h7: {
      color: "#979797",
      paddingLeft: "5px",
    },
    subtitle1: {
      textTransform: "uppercase",
      fontSize: "0.9em",
      lineHeight: 1.55,
    },
    subtitle2: {
      fontWeight: 400,
      fontSize: "0.9em",
      textTransform: "uppercase",
      color: "#a9a9a9",
    },
    button: {
      fontSize: "0.72em",
      fontWeight: 500,
      textTransform: "uppercase",
      letterSpacing: "1px",
      whiteSpace: "nowrap",
    },
    caption: {
      color: "#a5a5a5",
      textTransform: "uppercase",
      lineHeight: "12px",
      letterSpacing: "3px",
      fontWeight: 300,
      fontSize: "0.7em",
    },
    overline: {
      lineHeight: 1,
      textTransform: "none",
      // textShadow: "-1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff"
    },
  },
  overrides: {
    MuiAccordionSummary: {
      root: {
        "&.Mui-expanded": {
          backgroundColor: "#F0F0F0",
        },
      },
    },
    MuiCollapse: {
      root: {
        backgroundColor: "#F0F0F0",
      },
    },
    MuiSvgIcon: {
      root: {
        color: "black",
      },
    },
    MuiTabs: {
      indicator: {
        width: "52px",
        height: "52px",
        backgroundColor: "#eaeaea",
      },
    },
    MuiBox: {
      root: {
        padding: "12px",
      },
    },
    MuiIconButton: {
      root: {
        padding: "4px",
      },
    },
    MuiButton: {
      root: {
        borderRadius: "3px",
        lineHeight: 1.7,
        boxSizing: "border-box",
        fontSize: "10px",
        "&:hover": {
          backgroundColor: "none",
        },
      },
      outlined: {
        border: "none",
        padding: "5px 12px",
      },
      outlinedPrimary: {
        boxShadow: "0 0px 3px 0px #ccc",
        border: "none",
        backgroundColor: "white",
        "&:hover": {
          backgroundColor: "none",
          border: "none",
        },
      },
      outlinedSecondary: {
        marginTop: "5px",
        border: "1px solid #cccccc",
        backgroundColor: "white",
        color: "black",
        "&:hover": {
          backgroundColor: "white",
          border: "1px solid #cccccc",
        },
      },
      contained: {
        padding: "5px 12px",
      },
      containedPrimary: {
        boxShadow: "none",
        "&:hover": {
          boxShadow: "none",
        },
      },
      containedSecondary: {
        boxShadow: "none",
        "&:hover": {
          backgroundColor: "#8c0000",
          boxShadow: "none",
        },
      },
    },
    MuiOutlinedInput: {
      input: {
        fontSize: "1em",
        padding: "13px 11px",
        backgroundColor: "white",
        boxSizing: "border-box",
        height: "42px",
      },
      adornedEnd: {
        paddingRight: "9px",
      },
    },
    MuiInputLabel: {
      outlined: {
        transform: "translate(14px, 14px)",
        fontSize: "12px",
        color: "rgba(0, 0, 0, 0.38)",
      },
    },
    MuiFormControlLabel: {
      root: {
        marginRight: "30px",
      },
    },
    MuiChip: {
      root: {
        backgroundColor: "black",
        color: "white",
      },
      label: {
        lineHeight: 1.4,
      },
      clickable: {
        "&:hover": {
          backgroundColor: "black",
        },
        "&:focus": {
          backgroundColor: "black",
        },
      },
      deleteIcon: {
        color: "#f8f8f8",
        "&:hover": {
          color: "white",
        },
      },
      outlinedPrimary: {
        border: "1px solid #c4c4c4",
      },
      deleteIconOutlinedColorPrimary: {
        color: "#c4c4c4",
        "&:hover": {
          color: "#a5a5a5",
        },
      },
    },
    MuiInputAdornment: {
      root: {
        color: "white",
        "&:hover": {
          cursor: "pointer",
          color: "#e1e1e1",
        },
      },
    },
    MuiInputBase: {
      root: {
        paddingTop: "0",
      },
    },
    MuiCard: {
      root: {
        padding: "8px 1px 0",
        border: "none",
        boxShadow: "none",
      },
    },
    MuiCardHeader: {
      root: {
        padding: "0 0 8px",
      },
      avatar: {
        width: "100%",
        marginRight: 0,
      },
    },
    MuiFormLabel: {
      root: {
        fontSize: "1em",
      },
    },
    MuiBottomNavigation: {
      root: {
        height: "42px",
      },
    },
    MuiBottomNavigationAction: {
      root: {
        padding: "0 calc(1vw + 5px)",
        "&.Mui-selected": {
          paddingTop: 0,
          borderBottom: "2px solid black",
        },
      },
      label: {
        fontSize: "0.8rem",
        "&.Mui-selected": {
          paddingTop: "2px",
          fontSize: "0.8rem",
        },
      },
    },
    MuiTableContainer: {
      root: {
        borderRadius: "2px",
        border: "1px solid rgba(132, 132, 132, 0.13)",
        backgroundColor: "white",
      },
    },
    MuiTableCell: {
      root: {
        padding: "6px",
        fontSize: "0.9em",
      },
      paddingCheckbox: {
        width: "35px",
      },
      head: {
        fontWeight: 500,
      },
      footer: {
        fontWeight: 500,
        padding: "16px 6px",
        color: "rgb(0 0 0 / 0.9)",
      },
    },
    MuiTablePagination: {
      toolbar: {
        minHeight: "35px",
      },
      input: {
        fontSize: "13px",
      },
    },
    MuiPaper: {
      elevation8: {
        border: "1px solid #e7e7e7",
        boxShadow: "none",
      },
    },
    MuiSwitch: {
      root: {
        height: "37px",
      },
    },
    MuiSelect: {
      root: {
        fontSize: "12px",
      },
    },
    MUIDataTableToolbar: {
      root: {
        paddingTop: "5px",
        minHeight: "40px!important",
      },
      titleText: {
        fontSize: "1.3em",
        fontWeight: 400,
        lineHeight: 1.5,
        // margin: "0 0 15px"
      },
    },
    MUIDataTable: {
      paper: {
        border: "1px solid rgba(132, 132, 132, 0.13)",
        borderRadius: "2px",
        boxShadow: "none",
      },
    },
  },
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
  },
});

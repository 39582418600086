import React, { useState, useContext, useEffect } from "react";
import { RadioComponent } from "../../Widgets/RadioComponent";
import { makeStyles, Paper, Typography } from "@material-ui/core";
import { initialValue } from "../../../reducers/filterReducer";
import { filterContext } from "../../../context/filterContext";

const useStyles = makeStyles((theme) => ({
  root: theme.filterPaper,
}));

const CarryOverFilter = () => {
  const classes = useStyles();
  const [selected, setSelected] = useState(initialValue.isCo);
  const {
    state: { isCo },
  } = useContext(filterContext);

  useEffect(() => {
    setSelected(parseInt(isCo, 10));
  }, [isCo]);

  const options = [
    {
      display: "Carry Over + sprzedaż sezonowa",
      value: 2,
    },
    {
      display: "Tylko Carry Over",
      value: 1,
    },
    {
      display: "Tylko sprzedaż sezonowa",
      value: 0,
    },
  ];

  return (
    <Paper className={classes.root}>
      <Typography variant="h3" style={{ paddingBottom: 15 }}>
        Carry Over
      </Typography>
      <div style={{ paddingLeft: 5 }}>
        <RadioComponent
          name="isCo"
          options={options}
          state={selected}
          setState={setSelected}
        />
      </div>
    </Paper>
  );
};

export default CarryOverFilter;

import React, { useState, useContext, useEffect } from "react";
import {
  Button,
  Divider,
  Grid,
  InputAdornment,
  makeStyles,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import HighlightOffRoundedIcon from "@material-ui/icons/HighlightOffRounded";
import { filterContext } from "../../../context/filterContext";

const useStyles = makeStyles((theme) => ({
  root: theme.filterPaper,
  labels: theme.datePriceLabels,
  divider: theme.divider,
  inputField: {
    ...theme.inputField,
    ...theme.inputClearButton,
  },
}));

const PriceFilter = () => {
  const classes = useStyles();
  const [pFrom, setPFrom] = useState("");
  const [pTo, setPTo] = useState("");

  const {
    state: { priceFrom, priceTo },
  } = useContext(filterContext);

  useEffect(() => {
    setPFrom(priceFrom);
    setPTo(priceTo);
  }, [priceFrom, priceTo]);

  const handlePrice = (from, to) => {
    setPFrom(from);
    setPTo(to);
  };

  return (
    <Paper className={classes.root}>
      <Typography variant="h3">Zakres cen [PLN]</Typography>
      <div style={{ paddingTop: 15, paddingBottom: 10 }}>
        <Grid container item xs={12} spacing={1}>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handlePrice(0, 1000)}
            >
              0-1000
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handlePrice(1001, 2500)}
            >
              1001-2500
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handlePrice(2501, 5000)}
            >
              2501-5000
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={(e) => handlePrice(5000, 10000)}
            >
              5001-10000
            </Button>
          </Grid>
        </Grid>
      </div>

      <Grid container className={classes.labels}>
        <Grid item xs>
          <TextField
            key="price-from-id"
            label="Cena od [PLN]"
            value={pFrom ? `${pFrom} PLN` : pFrom}
            variant="outlined"
            onChange={(event) => handlePrice(event.target.value, pTo)}
            name="priceFrom"
            className={classes.inputField}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <HighlightOffRoundedIcon
                    style={{ color: "white" }}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      handlePrice("", pTo);
                    }}
                  />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item className={classes.divider}>
          <br />
          <Divider />
        </Grid>
        <Grid item xs>
          <TextField
            key="price-to-id"
            label="Cena do [PLN]"
            value={pTo ? `${pTo} PLN` : pTo}
            variant="outlined"
            onChange={(event) => handlePrice(pFrom, event.target.value)}
            name="priceTo"
            className={classes.inputField}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <HighlightOffRoundedIcon
                    style={{ color: "white" }}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      handlePrice(pFrom, "");
                    }}
                  />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default PriceFilter;

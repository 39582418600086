import React from "react";
import Lottie from "react-lottie";
import aniomationData from "../files/indicator.json";
import styled from "styled-components";

const MainContainer = styled("div")`
  width: 50;
  height: 100vh;
  display: flex;
  flex: 1;
  background-color: #f5f5f5;
  justify-content: center;
  align-items: center;
  padding: 15;
`;

const ReloadIndicator = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: aniomationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <MainContainer>
      <Lottie options={defaultOptions} height={"30%"} width={"30%"} />
    </MainContainer>
  );
};

export default ReloadIndicator;

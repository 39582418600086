import React, { useContext } from "react";
import { makeStyles, Paper, Typography, Grid, Button } from "@material-ui/core";
import { filterContext } from "../../../context/filterContext";
import {
  CLEAR_BY_QUERY,
  CLEAR_BY_VITKAC_URL,
  CLEAR_CATEGORY,
  CLEAR_FILTERS,
  CLEAR_IS_CO,
  CLEAR_PRICE_FROM,
  CLEAR_PRICE_TO,
  initialValue,
} from "../../../reducers/filterReducer";
import ActiveFilterItem from "./ActiveFilterItem";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import ActiveFilterDesigner from "./ActiveFilterDesigner";
import ActiveFilterSeason from "./ActiveFilterSeason";
import ActiveFilterMissingLinks from "./ActiveFilterMissingLinks";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "flex-start",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(0.5),
    },
    "& .MuiButtonBase-root": {
      margin: 0,
    },
    marginTop: theme.spacing(-1),
    "& .MuiChip-root": {
      color: theme.palette.common.black,
      backgroundColor: theme.palette.common.white,
      border: `1px solid ${theme.palette.divider}`,
      "& .MuiSvgIcon-root": {
        color: theme.palette.divider,
        "&:hover": {
          color: "#969696",
        },
      },
    },
  },
  paper: theme.filterPaper,
  removeButton: {
    height: "20px",
    padding: 0,
    letterSpacing: 0,
    "&:hover": {
      backgroundColor: "transparent",
    },
    "& .MuiButton-startIcon": {
      marginRight: "2px",
    },
    "& .MuiButton-iconSizeMedium > *:first-child": {
      fontSize: "18px",
    },
  },
}));

const ActiveFilters = () => {
  const classes = useStyles();
  const {
    dispatch,
    state: {
      priceFrom,
      priceTo,
      isCo,
      categoryIds,
      searchByVitkacUrl,
      searchByQuery,
      missingShopUrlSid,
      designerIds,
      seasonIds,
    },
  } = useContext(filterContext);

  let pFrom = "";
  let pTo = "";
  let qUrl = "";
  let qName = "";
  let category = "";
  let is_Co = "";

  const clearAll = () => {
    dispatch({ type: CLEAR_FILTERS });
  };

  const handleRemove = (actionType, payload = null) => {
    dispatch({ type: actionType, payload: payload });
  };

  const RemoveButton = (
    <Button
      color="primary"
      startIcon={<DeleteForeverIcon />}
      onClick={(e) => clearAll()}
      className={classes.removeButton}
    >
      USUŃ WSZYSTKIE
    </Button>
  );

  if (priceFrom) {
    pFrom = (
      <ActiveFilterItem
        label={`Cena od: ${priceFrom}`}
        type={CLEAR_PRICE_FROM}
        remove={handleRemove}
      ></ActiveFilterItem>
    );
  }

  if (priceTo) {
    pTo = (
      <ActiveFilterItem
        label={`Cena do: ${priceTo}`}
        type={CLEAR_PRICE_TO}
        remove={handleRemove}
      ></ActiveFilterItem>
    );
  }

  if (searchByVitkacUrl) {
    qUrl = (
      <ActiveFilterItem
        label={`Vitkac url: ${searchByVitkacUrl}`}
        type={CLEAR_BY_VITKAC_URL}
        remove={handleRemove}
      ></ActiveFilterItem>
    );
  }

  if (searchByQuery) {
    qName = (
      <ActiveFilterItem
        label={`Produkt: ${searchByQuery}`}
        type={CLEAR_BY_QUERY}
        remove={handleRemove}
      ></ActiveFilterItem>
    );
  }

  if (categoryIds) {
    category = (
      <ActiveFilterItem
        label={`Kategoria: ${categoryIds.name}`}
        type={CLEAR_CATEGORY}
        remove={handleRemove}
      ></ActiveFilterItem>
    );
  }

  if (isCo) {
    switch (isCo) {
      case "1":
        is_Co = (
          <ActiveFilterItem
            label="Tylko Carry Over"
            type={CLEAR_IS_CO}
            remove={handleRemove}
          ></ActiveFilterItem>
        );
        break;
      case "0":
        is_Co = (
          <ActiveFilterItem
            label="Tylko sprzedaż sezonowa"
            type={CLEAR_IS_CO}
            remove={handleRemove}
          ></ActiveFilterItem>
        );
        break;
      default:
    }
  }

  if (
    priceFrom ||
    priceTo ||
    parseInt(isCo) !== parseInt(initialValue.isCo) ||
    searchByVitkacUrl.length ||
    searchByQuery.length ||
    missingShopUrlSid.length ||
    categoryIds ||
    designerIds ||
    seasonIds
  ) {
    return (
      <Grid item xs={12} className="activeFilter" style={{ marginTop: "15px" }}>
        <Paper className={classes.paper}>
          <Grid container direction="column">
            <Grid item container justifyContent="space-between">
              <Typography variant="h3" style={{ paddingBottom: 15 }}>
                Aktywne filtry
              </Typography>
              {RemoveButton}
            </Grid>
            <Grid item className={classes.activeFilter}>
              <div className={classes.root}>
                {pFrom}
                {pTo}
                {qUrl}
                {qName}
                {category}
                {is_Co}
                <ActiveFilterDesigner />
                <ActiveFilterSeason />
                <ActiveFilterMissingLinks />
              </div>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    );
  } else {
    return null;
  }
};

export default ActiveFilters;

import React, { useState } from "react";
import styled from "styled-components";
import useAuth from "../../hooks/auth/useAuth";
import { login } from "../../api/auth";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";
import CircularProgress from "@material-ui/core/CircularProgress";
import useAlert from "../../hooks/useAlert";

const StyledButton = styled(Button)`
  ${({ theme }) => `
    margin: ${theme.spacing(3, 0)} !important;
    height: 30px;
    width: 100%;
  `}
`;

const StyledPaper = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 400px;

  & #login-form {
    width: 100%;
  }
`;

const LoginPageForm = () => {
  const { logIn } = useAuth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { error, success } = useAlert();

  const handleLogin = async () => {
    setIsLoading(true);

    const result = await login(email, password);
    if (!result.ok) {
      setIsLoading(false);
      error("Niepoprawne dane logowania");
      return;
    }
    const { token } = result.data;
    logIn(token);
    success("Zalogowano");
  };

  return (
    <>
      <StyledPaper>
        <img src={process.env.PUBLIC_URL + "/vitkac-logo.png"} alt="" />
        <br />
        <Typography component="h1" variant="h6">
          Logowanie
        </Typography>
        <div id="login-form">
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email"
            name="email"
            autoComplete="email"
            autoFocus
            onChange={(event) => setEmail(event.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Hasło"
            type="password"
            id="password"
            autoComplete="current-password"
            onChange={(event) => setPassword(event.target.value)}
          />
          <StyledButton
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            onClick={handleLogin}
          >
            {isLoading ? (
              <CircularProgress size={20} style={{ color: "white" }} />
            ) : (
              "Zaloguj"
            )}
          </StyledButton>
        </div>
        <Copyright />
      </StyledPaper>
    </>
  );
};

export default LoginPageForm;

const Copyright = () => {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://vitkac.com/">
        Vitkac
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
};

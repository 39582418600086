import React, { useEffect, useState, useContext } from "react";
import {
  makeStyles,
  Paper,
  Typography,
  Grid,
  FormControl,
  InputLabel,
  Select,
} from "@material-ui/core";
import { getCategories } from "../../../api/filters";
import { filterContext } from "../../../context/filterContext";

const useStyles = makeStyles((theme) => ({
  root: {
    ...theme.filterPaper,
    minWidth: "750px",
  },
  selects: {
    "& .MuiGrid-item:first-of-type": {
      paddingLeft: 0,
    },
    "& .MuiGrid-item:last-of-type": {
      paddingRight: 0,
    },
  },
  separator: {
    position: "relative",
    "&:not(:last-of-type):after": {
      content: "'\\203A'",
      height: "42px",
      position: "absolute",
      fontSize: "18px",
      top: "18px",
      right: "-3px",
    },
  },
  formControl: {
    width: "100%",
    "&:last-of-child": {},
  },
}));

const CategoryFilter = (props) => {
  const classes = useStyles();
  const [selected, setSelected] = useState("");
  const [afterFirstRender, setAfterFirstRender] = useState(false);
  const [data, setData] = useState([
    { level: 1, data: null, selectedId: null, fullPath: null },
    { level: 2, data: null, selectedId: null, fullPath: null },
    { level: 3, data: null, selectedId: null, fullPath: null },
    { level: 4, data: null, selectedId: null, fullPath: null },
    { level: 5, data: null, selectedId: null, fullPath: null },
  ]);
  const {
    state: { categoryIds },
  } = useContext(filterContext);

  useEffect(() => {
    LoadFirstLevelCategory();
    setAfterFirstRender(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!categoryIds && afterFirstRender) {
      LoadFirstLevelCategory();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryIds]);

  const LoadFirstLevelCategory = async () => {
    const result = await getCategories();

    if (!result.ok) {
      return;
    }

    const newArr = data.map((it) => {
      if (it.level === 1) {
        it.data = result.data;
        it.selectedId = null;
        it.fullPath = null;
      } else {
        it.data = null;
        it.selectedId = null;
        it.fullPath = null;
      }

      return it;
    });

    setData(newArr);
  };

  const updateCategoriesLevel = (nextLevel) => {
    const newArr = data.map((it) => {
      if (it.level >= nextLevel) {
        it.data = null;
        it.fullPath = null;
      }

      if (it.level >= nextLevel - 1) {
        it.selectedId = null;
        it.fullPath = null;
      }

      return it;
    });

    // findLastCategory
    setData(newArr);
    findLastCategory(newArr);
  };

  const findLastCategory = (newArr) => {
    let lastCategory = "";

    newArr.map((it) => {
      if (it.selectedId) {
        lastCategory = { id: it.selectedId, name: it.fullPath };
      }

      return it;
    });

    setSelected(lastCategory);
  };

  const LoadCategory = async (nextLevel, catId, fullPath) => {
    if (catId === null) {
      updateCategoriesLevel(nextLevel);
      return;
    }

    const result = await getCategories(catId);
    setSelected({ id: catId, name: fullPath });

    if (!result.ok) {
      return;
    }

    const newArr = data.map((it) => {
      if (it.level >= nextLevel) {
        it.data = null;
        it.selectedId = null;
        it.fullPath = null;
      }

      if (it.level === nextLevel - 1) {
        it.selectedId = catId;
        it.fullPath = fullPath;
      }

      if (it.level === nextLevel) {
        it.data = result.data;
      }

      return it;
    });

    setData(newArr);
  };

  return (
    <Paper className={classes.root}>
      <Typography variant="h3" style={{ paddingBottom: 10 }}>
        Kategorie
      </Typography>
      <input type="hidden" value={JSON.stringify(selected)} name="category" />
      <Grid container direction="column" alignItems="center" spacing={2}>
        <Grid container item xs={12} spacing={3} className={classes.selects}>
          {data.map((it, index) => {
            return it.data ? (
              <CategoryItem
                key={index}
                data={it.data}
                actualLevel={it.level}
                loadCategory={LoadCategory}
              />
            ) : (
              <CategoryItemDisabled key={`disabled=${index}`} />
            );
          })}
        </Grid>
      </Grid>
    </Paper>
  );
};

export default CategoryFilter;

const CategoryItem = ({ data, actualLevel, loadCategory }) => {
  const classes = useStyles();
  const [selected, setSelected] = useState("");
  const {
    state: { categoryIds },
  } = useContext(filterContext);

  useEffect(() => {
    if (!categoryIds && actualLevel === 1) {
      setSelected("Wybierz");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryIds]);

  const handleChange = (e) => {
    const selectedCatId = e.target.value;
    setSelected(selectedCatId);
    const fullPath =
      selectedCatId === "Wybierz"
        ? null
        : data.filter((it) => it.id === selectedCatId)[0].fullPath;

    loadCategory(
      actualLevel + 1,
      selectedCatId === "Wybierz" ? null : selectedCatId,
      fullPath
    );
  };

  return (
    <Grid item xs className={classes.separator}>
      <FormControl variant="outlined" className={classes.formControl}>
        <Select
          native
          disabled={data && data.length > 0 ? false : true}
          value={selected}
          onChange={(e) => handleChange(e)}
        >
          {data && data.length > 0 ? (
            <option>Wybierz</option>
          ) : (
            <option>Nieaktywne</option>
          )}
          {data.map((it) => {
            const id = `option-${it.id}`;
            return (
              <option
                key={id}
                value={it.id}
                data-id={it.id}
                data-path={it.fullPath}
              >
                {it.name}
              </option>
            );
          })}
        </Select>
      </FormControl>
    </Grid>
  );
};

const CategoryItemDisabled = () => {
  const classes = useStyles();
  return (
    <Grid item xs className={classes.separator}>
      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel>Nieaktywne</InputLabel>
        <Select value={""} native disabled={true}></Select>
      </FormControl>
    </Grid>
  );
};

// import { useContext } from "react";
// import { filterContext } from "../context/filterContext";

const useParamsState = () => {
  // const { state } = useContext(filterContext);

  const getParams = (state) => {
    const designerIds = state.designerIds.length
      ? state.designerIds.map((it) => it.id).join(",")
      : "";

    const categoryId = state.categoryIds.id ? state.categoryIds.id : "";

    const seasonIds = state.seasonIds.length
      ? state.seasonIds.map((it) => it.id).join(",")
      : "";

    const parseState = {
      ...state,
      designerIds: designerIds,
      categoryIds: categoryId,
      seasonIds: seasonIds,
    };

    return parseState;
  };

  return { getParams };
};

export default useParamsState;

import React, { useState, useEffect, useContext } from "react";
import { Paper, Typography, makeStyles } from "@material-ui/core";
import SelectedList from "./SelectedList";
import { getAllDesigners } from "../../../api/filters";
import { filterContext } from "../../../context/filterContext";

const useStyles = makeStyles((theme) => ({
  root: theme.filterPaper,
}));

const DesignerFilter = (props) => {
  const classes = useStyles();
  const [selected, setSelected] = useState([]);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const {
    state: { designerIds },
  } = useContext(filterContext);

  useEffect(() => {
    if (designerIds) {
      setSelected(designerIds);
    } else {
      setSelected([]);
    }
  }, [designerIds]);

  useEffect(() => {
    LoadDesigners();
  }, []);

  const LoadDesigners = async () => {
    const result = await getAllDesigners();

    if (!result.ok) {
      return;
    }

    setData(result.data);
    setIsLoading(false);
  };

  if (isLoading) return null;

  return (
    <Paper className={classes.root}>
      <Typography variant="h3" style={{ paddingBottom: 10 }}>
        Projektanci
      </Typography>
      <input
        type="hidden"
        value={selected.length ? JSON.stringify(selected) : ""}
        name="designers"
      />
      <SelectedList
        data={data}
        selectedIds={selected.map((it) => it.id)}
        selectedObj={selected}
        setSelected={setSelected}
        nameKey={"name"}
      />
    </Paper>
  );
};

export default DesignerFilter;

import React, { useContext } from "react";
import Grid from "@material-ui/core/Grid";
import { Select, Typography, Button } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import ArrowBackIosRoundedIcon from "@material-ui/icons/ArrowBackIosRounded";
import ArrowForwardIosRoundedIcon from "@material-ui/icons/ArrowForwardIosRounded";
import FirstPageRoundedIcon from "@material-ui/icons/FirstPageRounded";
import LastPageRoundedIcon from "@material-ui/icons/LastPageRounded";
import { makeStyles } from "@material-ui/core/styles";
import { filterContext } from "../../../context/filterContext";
import { SET_LIMIT_ON_PAGE, SET_PAGE } from "../../../reducers/filterReducer";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "15px",
    marginBottom: "15px",
  },
  formControl: {
    marginRight: theme.spacing(3),
    minWidth: 30,
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid rgba(132, 132, 132, 0.13)",
      borderRadius: 0,
    },
  },
  button: {
    borderRadius: 0,
    lineHeight: 1.7,
    boxSizing: "border-box",
    minWidth: 0,
    backgroundColor: "#323232",
    padding: "9px 4px",
    "&:disabled": {
      backgroundColor: "	#A9A9A9",
    },
  },
  buttonIcon: {
    height: "12px",
    color: "#D3D3D3",
  },
  paginationInput: theme.paginationSelect.input,
  paginationLabel: {
    marginRight: theme.spacing(1),
  },
}));

const Pagination = ({ totalPages, limitOptions = [5, 10, 15, 20] }) => {
  const classes = useStyles();

  const {
    dispatch,
    state: { page, limit },
  } = useContext(filterContext);

  const hasNextPage = !!(totalPages < parseInt(page) + 1);
  const hasPrevPage = !!(parseInt(page) > 1);

  const setPage = (page) => {
    dispatch({ type: SET_PAGE, payload: page });
  };

  const setLimit = (limit) => {
    dispatch({ type: SET_LIMIT_ON_PAGE, payload: limit });
  };

  const setPrev = () => {
    var prevPage = parseInt(page) > 1 ? parseInt(page) - 1 : parseInt(page);
    setPage(prevPage);
  };

  const setNext = () => {
    var nextPage = parseInt(page) + 1;
    setPage(nextPage);
  };

  const setFirst = () => {
    setPage(1);
  };

  const setLast = () => {
    setPage(totalPages);
  };

  const pageOptions = [...Array(totalPages)].map((it, index) => {
    return (
      <option key={index} value={index + 1}>
        {index + 1}
      </option>
    );
  });

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="flex-end"
      className={classes.root}
    >
      <Grid item>
        <Grid container direction="row" alignItems="center">
          <Grid item className={classes.noMargin}>
            <Typography variant="body1" className={classes.paginationLabel}>
              Wyświetl
            </Typography>
          </Grid>
          <Grid item>
            <FormControl
              style={{ minWidth: 30 }}
              variant="outlined"
              className={classes.formControl}
            >
              <Select
                native
                placeholder="Wybierz"
                value={limit}
                onChange={(event) => {
                  setLimit(parseInt(event.target.value, 10));
                  setPage(1);
                }}
                classes={{ root: classes.paginationInput }}
              >
                {limitOptions.map((it, index) => {
                  return (
                    <option key={index} value={it}>
                      {it}
                    </option>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>

      <Grid item>
        <Grid container direction="row" alignItems="center">
          <Grid item>
            <Typography variant="body1" className={classes.paginationLabel}>
              Strona:
            </Typography>
          </Grid>
          <Grid item>
            <FormControl
              style={{ minWidth: 30 }}
              variant="outlined"
              className={classes.formControl}
            >
              <Select
                native
                placeholder="Wybierz"
                value={page}
                onChange={(e) => {
                  setPage(e.target.value);
                }}
                classes={{ root: classes.paginationInput }}
              >
                {pageOptions}
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            <Button
              onClick={(e) => setFirst()}
              variant="contained"
              color="primary"
              className={classes.button}
              disabled={!hasPrevPage}
            >
              <FirstPageRoundedIcon className={classes.buttonIcon} />
            </Button>
            <Button
              onClick={(e) => setPrev()}
              variant="contained"
              color="primary"
              className={classes.button}
              disabled={!hasPrevPage}
            >
              <ArrowBackIosRoundedIcon className={classes.buttonIcon} />
            </Button>
            <Button
              onClick={(e) => setNext()}
              variant="contained"
              color="primary"
              className={classes.button}
              disabled={hasNextPage}
            >
              <ArrowForwardIosRoundedIcon className={classes.buttonIcon} />
            </Button>
            <Button
              onClick={(e) => setLast()}
              variant="contained"
              color="primary"
              className={classes.button}
              disabled={hasNextPage}
            >
              <LastPageRoundedIcon className={classes.buttonIcon} />
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Pagination;

import { BrowserRouter as Router, Switch } from "react-router-dom";
import { Route } from "react-router-dom";
import styled from "styled-components";
import "./App.css";
import AppMenu from "./components/Menu/AppMenu";
import ProductsPage from "./pages/ProductsPage";

const ContentContainer = styled("div")`
  ${({ theme }) => `
    background-color: #fafafa;
    display: flex;
    flex-direction: column;
    height: calc(100vh - ${theme.headerHeight});
    margin-top: 43px;
    padding: 15px;
  `}
`;

function App() {
  return (
    <Router>
      <AppMenu />
      <ContentContainer>
        <Switch exact={true}>
          <Route path="/" exact>
            <ProductsPage />
          </Route>
        </Switch>
      </ContentContainer>
    </Router>
  );
}

export default App;
